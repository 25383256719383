/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "NeueHaasGroteskDisplayPro45Light";
  src: url('/extras/fonts/NeueHaasGroteskDisplayPro45Light/font.woff2') format('woff2'), url('/extras/fonts/NeueHaasGroteskDisplayPro45Light/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHaasGroteskDisplayPro55Roman";
  src: url('/extras/fonts/NeueHaasGroteskDisplayPro55Roman/font.woff2') format('woff2'), url('/extras/fonts/NeueHaasGroteskDisplayPro55Roman/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHaasGroteskDisplayPro65Medium";
  src: url('/extras/fonts/NeueHaasGroteskDisplayPro65Medium/font.woff2') format('woff2'), url('/extras/fonts/NeueHaasGroteskDisplayPro65Medium/font.woff') format('woff');
}
@font-face {
  font-family: "NeueHaasGroteskDisplayPro75Bold";
  src: url('/extras/fonts/NeueHaasGroteskDisplayPro75Bold/font.woff2') format('woff2'), url('/extras/fonts/NeueHaasGroteskDisplayPro75Bold/font.woff') format('woff');
}
@font-face {
  font-family: "NexaRustSansBook";
  src: url('/extras/fonts/NexaRustSansBook/font.woff2') format('woff2'), url('/extras/fonts/NexaRustSansBook/font.woff') format('woff');
}
@font-face {
  font-family: "NexaRustSansBlack";
  src: url('/extras/fonts/NexaRustSansBlack/font.woff2') format('woff2'), url('/extras/fonts/NexaRustSansBlack/font.woff') format('woff');
}
@font-face {
  font-family: "NexaRustSansBlack1";
  src: url('/extras/fonts/NexaRustSansBlack1/font.woff2') format('woff2'), url('/extras/fonts/NexaRustSansBlack1/font.woff') format('woff');
}
@font-face {
  font-family: "NexaRustScriptL1";
  src: url('/extras/fonts/NexaRustScriptL1/font.woff2') format('woff2'), url('/extras/fonts/NexaRustScriptL1/font.woff') format('woff');
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 10px;
  margin-bottom: 10px;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #000;
  color: #000;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #71579e;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #e95c36;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #e95c36;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
    letter-spacing: 0.5px;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(233, 92, 54, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 17px;
  font-size: 1.7rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'NeueHaasGroteskDisplayPro55Roman', helvetica, sans-serif;
  line-height: 1.333;
  letter-spacing: 0.5px;
}
#desk {
  display: none !important;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1144px;
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.desk.small {
  width: 960px;
}
.home {
  float: left;
  display: block;
  -o-transition: top 0.218s;
  -moz-transition: top 0.218s;
  -webkit-transition: top 0.218s;
  transition: top 0.218s;
}
.home.home-large {
  width: 215px;
  height: 76px;
  opacity: 1;
  background: url(/images/logo-large.svg) no-repeat 0 0;
  background-size: 100% auto;
  -o-transition: top 0.218s, opacity 0.218s;
  -moz-transition: top 0.218s, opacity 0.218s;
  -webkit-transition: top 0.218s, opacity 0.218s;
  transition: top 0.218s, opacity 0.218s;
}
.home.home-small {
  width: 82px;
  height: 76px;
  background: url(/images/logo-small.svg) no-repeat 0 0;
  background-size: 100% auto;
  opacity: 0;
  -o-transition: top 0.218s, opacity 0.218s 0.2s;
  -moz-transition: top 0.218s, opacity 0.218s 0.2s;
  -webkit-transition: top 0.218s, opacity 0.218s 0.2s;
  transition: top 0.218s, opacity 0.218s 0.2s;
}
#head {
  float: left;
  width: 100%;
  margin-top: 100px;
}
.cb-layout2 #head {
  margin-top: 0;
}
#cb-elastic-container {
  float: left;
  width: 100%;
}
#cb-elastic-content {
  float: left;
  width: 100%;
}
#meta {
  z-index: 220;
}
#wrapper {
  float: left;
  width: 100%;
}
#header {
  width: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  z-index: 2001;
  background: #f9f7f4;
}
#multimood {
  float: left;
  width: 100%;
  margin-top: 130px;
  position: relative;
}
.moodcontent {
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}
.cb-layout2 .moodcontent {
  display: block;
}
#content {
  float: left;
  width: 100%;
}
.maincontent {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.cb-layout2 .maincontent {
  margin-top: 0;
}
#edit.cb-layout1 .maincontent {
  border-top: 3px dashed #ccc;
}
#basecontent {
  float: left;
  width: 100%;
  display: none;
  background: #eaf5f9;
}
.cb-layout2 #basecontent {
  display: block;
}
#southcontent {
  float: left;
  width: 100%;
  display: none;
  background: #f9f7f4;
}
.cb-layout2 #southcontent {
  display: block;
}
#footer {
  float: left;
  width: 100%;
  margin-top: 54px;
  background: #e95c36;
}
.cb-layout2 #footer {
  margin-top: 0;
}
.footpart {
  float: left;
  width: 33.33333333%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 90px 0;
}
.footpart.vcard {
  padding-left: 80px;
  background: url(/images/icon-flieger.svg) no-repeat 0 0;
}
.footpart.spenden {
  padding-left: 60px;
  background: url(/images/icon-heart.svg) no-repeat 0 0;
}
.footpart.newsletter {
  padding-left: 70px;
  background: url(/images/icon-hand.svg) no-repeat 0 0;
}
.foottitle {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.1em;
  margin-top: 6px;
  margin-bottom: 28px;
  font-family: 'NexaRustSansBook', helvetica, sans-serif;
  letter-spacing: 0;
}
.footsubtitle {
  display: block;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.foottext {
  display: block;
}
.foottext.iban a {
  color: #000;
  text-decoration: none;
}
.foottext.newslink {
  color: #000;
  text-decoration: none;
}
.footlink {
  display: block;
  color: #000;
  text-decoration: underline;
}
.footlink:hover,
.footlink:focus {
  color: #fff;
}
.footlink--facebook {
  display: inline-block;
  background-size: 40px 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/social-facebook.svg);
  font-size: 17px;
  line-height: 1.17647059;
  padding: 10px 0 10px 50px;
  margin-top: 10px;
  text-decoration: none;
}
.footlink--facebook:hover,
.footlink--facebook:focus {
  text-decoration: none;
}
.vcard .tel {
  display: block;
}
#services {
  float: right;
  margin: 42px 46px 41px 0;
  display: block;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#services .meta {
  float: left;
  font-size: 17px;
  font-family: 'NexaRustScriptL1', helvetica, sans-serif;
  color: #000;
  line-height: 1;
  border-right: 1px solid #ccc;
  padding: 30px 24px 0;
}
#services .meta:hover,
#services .meta:focus {
  text-decoration: none;
  color: #e95c36;
}
#services .meta.service_custcontact {
  background: url(/images/icon-flieger-or.svg) no-repeat 50% 0;
}
#services .meta.service_hunde {
  border-right: none;
  background: url(/images/icon-tatze-or.svg) no-repeat 50% 0;
}
#services2 {
  float: right;
  margin-right: 16px;
  margin-top: 20px;
  display: block;
}
#services2 .meta {
  float: left;
  font-size: 17px;
  font-family: 'NexaRustScriptL1', helvetica, sans-serif;
  letter-spacing: 0;
  color: #000;
  line-height: 1;
  border-right: 1px solid #ccc;
  padding: 30px 24px 0;
}
#services2 .meta:hover,
#services2 .meta:focus {
  text-decoration: none;
  color: #e95c36;
}
#services2 .meta.service_custcontact {
  background: url(/images/icon-flieger-or.svg) no-repeat 50% 0;
}
#services2 .meta.service_hunde {
  border-right: none;
  background: url(/images/icon-tatze-or.svg) no-repeat 50% 0;
}
#newsletter {
  float: left;
  width: 100%;
  text-align: left;
}
#mc_embed_signup {
  float: left;
  width: 100%;
}
#mc_embed_signup .indicates-required {
  font-size: 14px;
  margin-bottom: 25px;
  float: left;
  width: 100%;
}
#mc_embed_signup .mc-field-group {
  float: left;
  width: 100%;
  font-size: 17px;
  line-height: 1.333;
  margin: 10px 0;
}
#mc_embed_signup .mc-field-group label {
  float: left;
  width: 30%;
  margin-top: 9px;
}
#mc_embed_signup .mc-field-group input {
  width: 70%;
  border: 1px solid #7e65aa;
  border-radius: 2px;
  height: 40px;
  padding: 0 10px;
  font-size: 17px;
  color: #000;
}
#mc_embed_signup input.button {
  float: right;
  margin-top: 10px;
  border: 2px solid #7e65aa;
  padding: 12px 40px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  background: none;
  min-height: 0;
  line-height: 1.2;
  text-shadow: none;
  border-radius: 0;
  font-size: 16px;
  color: #7e65aa;
}
#mc_embed_signup input.button:hover,
#mc_embed_signup input.button:focus {
  text-decoration: none;
  background: #7e65aa;
  color: #fff;
  cursor: pointer;
}
#mc_embed_signup input.button:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset;
}
#spend {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  width: 420px;
  height: 642px;
  mix-blend-mode: multiply;
  background: url(/images/spend2.png) no-repeat 0 0;
}
@media (max-width: 1023px) {
  #spend {
    display: none;
  }
}
h1 {
  text-align: center;
  color: #594845;
  font-weight: normal;
  font-size: 19px;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-family: 'NexaRustSansBook', helvetica, sans-serif;
  letter-spacing: 0;
}
.cb-layout2 h1 {
  font-family: 'NexaRustSansBlack1', helvetica, sans-serif;
  font-size: 67px;
  line-height: 1.08;
  color: #fff;
  letter-spacing: 0;
}
h2 {
  font-size: 25px;
  padding-bottom: 8px;
  font-weight: normal;
}
.north h2 {
  color: #7e65aa;
  font-size: 44px;
  line-height: 1.1;
  padding-bottom: 25px;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.cb-layout2 h2 {
  font-size: 19px;
  font-family: 'NexaRustSansBook', helvetica, sans-serif;
  letter-spacing: 0;
  line-height: 1.1;
  color: #594845;
  padding-bottom: 0;
}
.cb-layout2 .base h2 {
  font-size: 19px;
}
.cb-layout2 .farnorth h2 {
  color: #fff;
}
.south h2 {
  padding-bottom: 38px;
}
.east h2 {
  color: #fff;
  text-transform: none;
  font-family: 'NexaRustScriptL1', helvetica, sans-serif;
  font-size: 41px;
  line-height: 1;
  font-weight: normal;
}
@media (max-width: 767px) {
  .east h2 {
    font-size: 41px !important;
    line-height: 1 !important;
  }
}
h3 {
  font-family: 'NexaRustScriptL1', helvetica, sans-serif;
  letter-spacing: 0;
  font-size: 50px;
  line-height: 1.09;
  font-weight: normal;
  padding: 30px 0;
}
div.side div.pure h3 {
  color: #7e65aa;
  font-size: 30px;
  line-height: 1.15;
  padding: 0 0 20px 0;
  text-align: center;
}
h4 {
  font-weight: normal;
  font-size: 70px;
  line-height: 1.08;
  color: #594845;
  font-family: 'NexaRustSansBlack1', helvetica, sans-serif;
  letter-spacing: 0;
  padding: 50px 0;
}
h5 {
  font-weight: normal;
  font-size: 41px;
  line-height: 1.2;
}
strong {
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #4d4d4d;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #7e65aa;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.line {
  margin-top: 26px;
  margin-bottom: 3px;
}
div.line hr {
  border-color: #ccc;
  color: #ccc;
}
div.pict + div.line {
  margin-top: 31px;
}
div.list {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.list .gist {
  list-style-type: none;
  margin-left: 0;
  padding-left: 16px;
  background: url(/images/list.png) no-repeat 0 0.7em;
}
div.link {
  background: none !important;
  margin-top: 20px;
}
div.link a.open {
  margin-left: 0;
  border: 2px solid #7e65aa;
  display: inline-block;
  padding: 12px 40px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  text-decoration: none;
}
div.link a.open:hover,
div.link a.open:focus {
  text-decoration: none;
  background: #7e65aa;
  color: #fff;
}
div.link a.open:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset;
}
.cb-layout2 .main div.link a.open {
  font-size: 16px;
}
div.load {
  background: none !important;
}
div.load a.load {
  margin-left: 0;
  padding: 2px 0 2px 25px;
  line-height: 21px;
  background: url(/images/load.svg) no-repeat 0 0;
}
div.line hr {
  border-color: #000;
  color: #000;
}
.cb-layout2 div.main {
  text-align: center;
  margin-top: 72px;
}
div.side {
  margin-top: 8px;
}
div.side div.pure div.text p.norm {
  text-align: center;
  color: #7e65aa;
  font-size: 19px;
}
div.north {
  text-align: center;
  margin-top: 67px;
  font-size: 25px;
  line-height: 1.4;
}
div.north .text {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
div.farnorth {
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
div.farnorth div.unit {
  width: 100%;
  margin: 0;
}
div.farnorth div.unit div.part,
div.farnorth div.unit div.body,
div.farnorth div.unit div.head h2 {
  margin: 0;
}
div.farnorth div.unit div.part {
  width: 100%;
}
div.base {
  text-align: center;
  margin-top: 67px;
  margin-bottom: 70px;
  font-size: 22px;
  line-height: 1.4;
}
div.base div.pict img {
  margin: 0 auto;
}
div.south {
  text-align: center;
  margin-top: 77px;
  margin-bottom: 50px;
}
div.east {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 100;
  width: 266px;
  color: #fff;
  text-align: center;
}
div.east a {
  color: #fff;
}
@media (max-width: 1023px) {
  div.east {
    position: relative;
    left: 0;
    bottom: 0;
    float: left;
    width: 100%;
    background-color: #e95c36;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
}
.side .unit {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.side .unit .part,
.side .unit .head h2 {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.main,
.side,
.north {
  display: flex;
  flex-wrap: wrap;
}
.main .seam,
.side .seam,
.north .seam,
.main .flat,
.side .flat,
.north .flat,
.main .edge,
.side .edge,
.north .edge {
  text-align: left;
  box-sizing: border-box;
  padding: 20px 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.main .seam .head,
.side .seam .head,
.north .seam .head,
.main .flat .head,
.side .flat .head,
.north .flat .head,
.main .edge .head,
.side .edge .head,
.north .edge .head {
  flex-grow: 0;
}
.main .seam .body,
.side .seam .body,
.north .seam .body,
.main .flat .body,
.side .flat .body,
.north .flat .body,
.main .edge .body,
.side .edge .body,
.north .edge .body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#edit .main .seam .body,
#edit .side .seam .body,
#edit .north .seam .body,
#edit .main .flat .body,
#edit .side .flat .body,
#edit .north .flat .body,
#edit .main .edge .body,
#edit .side .edge .body,
#edit .north .edge .body {
  min-height: 0;
}
.main .seam .part,
.side .seam .part,
.north .seam .part,
.main .flat .part,
.side .flat .part,
.north .flat .part,
.main .edge .part,
.side .edge .part,
.north .edge .part,
.main .seam .head h2,
.side .seam .head h2,
.north .seam .head h2,
.main .flat .head h2,
.side .flat .head h2,
.north .flat .head h2,
.main .edge .head h2,
.side .edge .head h2,
.north .edge .head h2 {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main .seam .link:last-child,
.side .seam .link:last-child,
.north .seam .link:last-child,
.main .flat .link:last-child,
.side .flat .link:last-child,
.north .flat .link:last-child,
.main .edge .link:last-child,
.side .edge .link:last-child,
.north .edge .link:last-child {
  padding-top: 20px;
  margin-top: auto;
}
.main .seam .pict.tall:first-child,
.side .seam .pict.tall:first-child,
.north .seam .pict.tall:first-child,
.main .flat .pict.tall:first-child,
.side .flat .pict.tall:first-child,
.north .flat .pict.tall:first-child,
.main .edge .pict.tall:first-child,
.side .edge .pict.tall:first-child,
.north .edge .pict.tall:first-child,
.main .seam .cb-youtube.tall:first-child,
.side .seam .cb-youtube.tall:first-child,
.north .seam .cb-youtube.tall:first-child,
.main .flat .cb-youtube.tall:first-child,
.side .flat .cb-youtube.tall:first-child,
.north .flat .cb-youtube.tall:first-child,
.main .edge .cb-youtube.tall:first-child,
.side .edge .cb-youtube.tall:first-child,
.north .edge .cb-youtube.tall:first-child {
  width: calc(100% + 60px) !important;
  margin: -20px -30px 20px !important;
}
@media (max-width: 767px) {
  .main .seam .pict.tall:first-child,
  .side .seam .pict.tall:first-child,
  .north .seam .pict.tall:first-child,
  .main .flat .pict.tall:first-child,
  .side .flat .pict.tall:first-child,
  .north .flat .pict.tall:first-child,
  .main .edge .pict.tall:first-child,
  .side .edge .pict.tall:first-child,
  .north .edge .pict.tall:first-child,
  .main .seam .cb-youtube.tall:first-child,
  .side .seam .cb-youtube.tall:first-child,
  .north .seam .cb-youtube.tall:first-child,
  .main .flat .cb-youtube.tall:first-child,
  .side .flat .cb-youtube.tall:first-child,
  .north .flat .cb-youtube.tall:first-child,
  .main .edge .cb-youtube.tall:first-child,
  .side .edge .cb-youtube.tall:first-child,
  .north .edge .cb-youtube.tall:first-child {
    width: calc(100% + 40px) !important;
    margin: -20px -20px 20px !important;
  }
}
#edit .main .seam .pict.tall:first-child,
#edit .side .seam .pict.tall:first-child,
#edit .north .seam .pict.tall:first-child,
#edit .main .flat .pict.tall:first-child,
#edit .side .flat .pict.tall:first-child,
#edit .north .flat .pict.tall:first-child,
#edit .main .edge .pict.tall:first-child,
#edit .side .edge .pict.tall:first-child,
#edit .north .edge .pict.tall:first-child,
#edit .main .seam .cb-youtube.tall:first-child,
#edit .side .seam .cb-youtube.tall:first-child,
#edit .north .seam .cb-youtube.tall:first-child,
#edit .main .flat .cb-youtube.tall:first-child,
#edit .side .flat .cb-youtube.tall:first-child,
#edit .north .flat .cb-youtube.tall:first-child,
#edit .main .edge .cb-youtube.tall:first-child,
#edit .side .edge .cb-youtube.tall:first-child,
#edit .north .edge .cb-youtube.tall:first-child {
  margin-top: 0 !important;
}
.main .seam .pict.tall:last-child,
.side .seam .pict.tall:last-child,
.north .seam .pict.tall:last-child,
.main .flat .pict.tall:last-child,
.side .flat .pict.tall:last-child,
.north .flat .pict.tall:last-child,
.main .edge .pict.tall:last-child,
.side .edge .pict.tall:last-child,
.north .edge .pict.tall:last-child,
.main .seam .cb-youtube.tall:last-child,
.side .seam .cb-youtube.tall:last-child,
.north .seam .cb-youtube.tall:last-child,
.main .flat .cb-youtube.tall:last-child,
.side .flat .cb-youtube.tall:last-child,
.north .flat .cb-youtube.tall:last-child,
.main .edge .cb-youtube.tall:last-child,
.side .edge .cb-youtube.tall:last-child,
.north .edge .cb-youtube.tall:last-child {
  margin-bottom: -20px !important;
}
.main .seam .link a.open,
.side .seam .link a.open,
.north .seam .link a.open,
.main .flat .link a.open,
.side .flat .link a.open,
.north .flat .link a.open,
.main .edge .link a.open,
.side .edge .link a.open,
.north .edge .link a.open {
  background-color: #7e65aa;
  color: #fff;
}
.main .seam .link a.open:hover,
.side .seam .link a.open:hover,
.north .seam .link a.open:hover,
.main .flat .link a.open:hover,
.side .flat .link a.open:hover,
.north .flat .link a.open:hover,
.main .edge .link a.open:hover,
.side .edge .link a.open:hover,
.north .edge .link a.open:hover,
.main .seam .link a.open:focus,
.side .seam .link a.open:focus,
.north .seam .link a.open:focus,
.main .flat .link a.open:focus,
.side .flat .link a.open:focus,
.north .flat .link a.open:focus,
.main .edge .link a.open:focus,
.side .edge .link a.open:focus,
.north .edge .link a.open:focus {
  background-color: #e95c36;
  border-color: #e95c36;
}
.main .seam,
.side .seam,
.north .seam {
  background-color: #f9f7f4;
}
.main .flat,
.side .flat,
.north .flat {
  background-color: #7e65aa;
  color: #fff;
}
.main .flat a,
.side .flat a,
.north .flat a {
  color: #fff;
  text-decoration: underline;
}
.main .flat a:hover,
.side .flat a:hover,
.north .flat a:hover,
.main .flat a:focus,
.side .flat a:focus,
.north .flat a:focus {
  text-decoration: none;
}
.main .flat .link a.open,
.side .flat .link a.open,
.north .flat .link a.open {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.main .flat .link a.open:hover,
.side .flat .link a.open:hover,
.north .flat .link a.open:hover,
.main .flat .link a.open:focus,
.side .flat .link a.open:focus,
.north .flat .link a.open:focus {
  background-color: #fff;
  color: #7e65aa;
  border-color: #fff;
}
.main .edge,
.side .edge,
.north .edge {
  color: #fff;
  min-height: 400px;
}
#view .main .edge .head,
#view .side .edge .head,
#view .north .edge .head {
  display: none;
}
.main .edge .part,
.side .edge .part,
.north .edge .part {
  position: relative;
  z-index: 2;
}
.main .edge .pict.tall:first-child,
.side .edge .pict.tall:first-child,
.north .edge .pict.tall:first-child {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100% !important;
  margin: 0 !important;
  height: 100%;
  background-color: #000;
}
.main .edge .pict.tall:first-child *,
.side .edge .pict.tall:first-child *,
.north .edge .pict.tall:first-child * {
  height: 100%;
}
.main .edge .pict.tall:first-child img,
.side .edge .pict.tall:first-child img,
.north .edge .pict.tall:first-child img {
  width: 100%;
  height: 100%;
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: cover;
  object-position: center;
  opacity: 0.6;
}
.main .edge .link a.open,
.side .edge .link a.open,
.north .edge .link a.open {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.main .edge .link a.open:hover,
.side .edge .link a.open:hover,
.north .edge .link a.open:hover,
.main .edge .link a.open:focus,
.side .edge .link a.open:focus,
.north .edge .link a.open:focus {
  background-color: #fff;
  color: #7e65aa;
  border-color: #fff;
}
div.east .unit {
  margin: 0 !important;
  width: 100% !important;
}
@media (max-width: 1023px) {
  div.east .unit {
    max-width: 266px;
  }
}
div.east .unit .part,
div.east .unit div.head h2 {
  margin: 0 !important;
  width: 100% !important;
}
div.east .unit .link {
  margin-top: 10px !important;
}
div.east .unit .link a.open {
  background-color: transparent;
  border-color: #fff;
  padding: 12px 20px 12px 48px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  color: #fff;
  display: block;
  background: url(/images/icon-heart-small.svg) no-repeat 15px 50%;
}
div.east .unit .link a.open:hover,
div.east .unit .link a.open:focus {
  text-decoration: none;
  color: #e95c36;
  background: #fff url(/images/icon-heart-small-or.svg) no-repeat 15px 50%;
}
div.east .unit .link a.open:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset;
}
div.form input.submit {
  border: 2px solid #7e65aa;
  padding: 12px 40px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro75Bold', helvetica, sans-serif;
  letter-spacing: 0.5px;
  background: none;
  min-height: 0;
  line-height: 1.2;
  text-shadow: none;
  border-radius: 0;
  font-size: 16px;
  color: #7e65aa;
}
div.form input.submit:hover,
div.form input.submit:focus {
  text-decoration: none;
  background: #7e65aa;
  color: #fff;
}
div.form input.submit:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.333em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro55Roman', helvetica, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.09444444em 3px;
  min-height: 1.333em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 18px;
  font-size: 1.8rem;
  color: #444;
  font-weight: normal;
  font-family: 'NeueHaasGroteskDisplayPro55Roman', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 18px;
  font-size: 1.8rem;
  display: inline-block;
  padding: 0.09444444em 1em;
  min-height: 1.333em;
  border: 1px solid;
  border-color: #e6491f #be3915 #be3915 #e6491f;
  border-radius: 2px;
  background-color: #e95c36;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(167, 50, 18, 0.75);
  line-height: 1.333em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #e6491f #be3915 #be3915 #e6491f;
  background-color: #eb6744;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #e6491f #be3915 #be3915 #e6491f;
  background-color: #d53f17;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.333em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 29.375%;
  position: relative;
}
@media (max-width: 1001px) {
  #slides {
    padding-bottom: 294px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.c2 #slides img {
  object-position: right center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #e95c36;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #e95c36;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 282px;
  height: 100%;
  z-index: 2000;
  overflow: scroll;
  background: #fff;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.togglenavigation {
  position: relative;
  float: right;
  margin-top: 44px;
  margin-right: 20px;
  display: block;
  width: 19px;
  height: 12px;
  z-index: 2002;
  cursor: pointer;
  color: #000 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.togglenavigation:active > span {
  background: #000000 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 2px;
  width: 19px;
  margin-top: -1px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -6px;
}
.togglenavigation > .tline-4 {
  margin-top: 4px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
/* RIGHT */
#mobile-navi {
  right: -282px;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  text-align: right;
  display: block;
  color: #000;
  padding: 11px 40px;
  padding-left: 46px;
  font-size: 18px;
}
#mobile-navi .navi .item .menu.path {
  color: #e95c36;
}
#mobile-navi .navi .item .menu:hover,
#mobile-navi .navi .item .menu:focus {
  text-decoration: none;
}
#mobile-navi .navi.sub1 {
  margin-top: 150px;
  margin-bottom: 30px;
}
#mobile-navi .navi.sub1 .item .menu {
  font-family: 'NexaRustSansBlack1', helvetica, sans-serif;
  letter-spacing: 0;
}
#mobile-navi .navi.sub2 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item.exit {
  margin-bottom: 20px;
}
#mobile-navi .navi.sub2 .item .menu {
  text-transform: none;
  font-family: 'NeueHaasGroteskDisplayPro55Roman', helvetica, sans-serif;
  padding: 3px 40px;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 46px;
  height: 46px;
  background: url(/images/mnavi.svg) no-repeat 50% 50%;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
@media only screen and (max-width: 767px) {
  #mobile-navi .navi .item .menu {
    font-size: 14px;
    padding-left: 41px;
  }
  #mobile-navi .navi.sub1 {
    margin-top: 120px;
  }
  #mobile-navi .cb-toggle {
    width: 41px;
    height: 41px;
    background-size: 15px auto;
  }
}
/******* layout-small.less 2013-1-16 *******/
@media only screen and (max-width: 767px) {
  .home {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
  }
  .cb-elastic-content-small .home {
    top: 7px;
  }
  .home.home-large {
    height: 59px;
    width: 166px;
  }
  .cb-elastic-content-small .home.home-large {
    opacity: 0;
    width: 0;
    -o-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -moz-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -webkit-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  }
  .home.home-small {
    width: 64px;
    height: 59px;
  }
  .cb-elastic-content-small .home.home-small {
    opacity: 1;
    -o-transition: top 0.218s;
    -moz-transition: top 0.218s;
    -webkit-transition: top 0.218s;
    transition: top 0.218s;
  }
}
@media only screen and (max-width: 767px) {
  #head,
  div.area div.unit,
  .footdesk {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  #services {
    display: none;
  }
  #multimood {
    margin-top: 100px;
  }
  .togglenavigation {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .cb-elastic-content-small .togglenavigation {
    margin-top: 34px;
    margin-bottom: 34px;
  }
  #footer {
    padding: 40px 0;
  }
  .footpart {
    width: 100%;
    margin: 22px 0;
    padding-left: 0 !important;
    padding-top: 55px;
  }
  .moodcontent {
    bottom: 23px;
    -o-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  div.farnorth {
    margin-top: 12px;
  }
  div.base {
    margin-top: 36px;
    margin-bottom: 43px;
  }
  .cb-layout2 div.main {
    margin-top: 52px;
  }
  div.south {
    margin-top: 46px;
  }
  #head {
    margin-top: 52px;
  }
  div.north {
    margin-top: 50px;
  }
  #mc_embed_signup .mc-field-group label {
    width: 100%;
    margin-top: 0;
  }
  #mc_embed_signup .mc-field-group input {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #spend {
    background: #7e65aa;
    width: 100%;
    height: 36px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 15px;
    line-height: 1.333;
  }
  .cb-layout2 h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 21px;
  }
  .cb-layout2 h2 {
    font-size: 15px;
    line-height: 1.333;
  }
  .cb-layout2 .main h2 {
    padding-bottom: 36px;
  }
  .north h2 {
    font-size: 36px;
    line-height: 1.15;
    padding-bottom: 8px;
  }
  h3 {
    font-size: 30px;
    line-height: 1.15;
    padding-top: 16px;
    padding-bottom: 18px;
  }
  .base h3 {
    font-size: 40px;
  }
  h4 {
    font-size: 26px;
    padding: 12px 0 18px;
  }
  h5 {
    font-size: 35px;
    hyphens: auto;
  }
  .side h5,
  .cb-layout1 .main h5,
  .cb-layout3 .main h5,
  .cb-layout2 .main .unit h5 {
    font-size: 26px;
  }
  .cb-layout2 .main .unit.pure.wide h5 {
    font-size: 35px;
  }
  div.base {
    font-size: 18px;
  }
  div.north {
    font-size: 18px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.cb-layout1 .main {
  margin-top: 8px;
}
.cb-layout1 .side {
  margin-top: 30px;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */